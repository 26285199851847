.Home .lander {
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .navMenu {
  position: fixed;
}

.Home .menuContent {
  max-height:100%;
  overflow-y: auto;
  overflow-x: hidden;
}