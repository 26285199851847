@media all and (min-width: 480px) {
    .Contact form {
        padding: 0 0;
        margin: 0 0;
        max-width: 560px;
    }

    .Contact form textarea {
        height: 118px;
        font-size: 1.1rem;
      }
  }
  