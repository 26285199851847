.Accounts h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.ag-header-cell-label {
    justify-content: center; 
    font-weight: 600;
    font-size:20px;
 } 

.ag-header-cell {
  margin: 0; 
} 

.ag-header-group-cell-label {
  justify-content: center; 
  font-weight: 600;
  font-size:20px;
} 

#buttons { padding-top: 5px}