.Transactions h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.ag-header-cell-label {
    font-weight: 600;
    font-size:20px;
 } 

.ag-header-group-cell-label {
  font-weight: 600;
  font-size:20px;
} 


 #buttons { padding-top: 5px}